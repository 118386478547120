import Vue, { VNode, CreateElement, RenderContext } from "vue";
import { DataTableHeader } from "vuetify";
import { calculateAdditionalRowValues } from "./calculateAdditionalRowValues";

export default Vue.extend({
  name: "AnalysisTableAdditionalRow",
  functional: true,
  props: {
    flatValues: {
      type: Object,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  render(
    createElement: CreateElement,
    context: RenderContext
  ): VNode | VNode[] {
    const th = context.props.headers.map((header: DataTableHeader): VNode => {
      const { cellClass, finalValue, originalValue } =
        calculateAdditionalRowValues(
          context.props.type,
          header.value,
          context.props.flatValues
        );

      let value;

      switch (header.value) {
        case "project.projectContact.fullTimeEquivalent":
        case "projectSales.calculationResults.duration":
        case "transactionMultiples.turnover":
        case "transactionMultiples.netProfit":
        case "transactionMultiples.ebt":
        case "transactionMultiples.ebit":
        case "transactionMultiples.ebitda":
          value = String(originalValue.toFixed(1));
          break;
        case "projectSales.calculationResults.fixedPriceRatio":
        case "projectSales.calculationResults.earnOutMaxPercentage":
        case "projectSales.calculationResults.ownerLoanPercentage":
          value = `${originalValue.toFixed(1)}%`;
          break;
        default:
          value = finalValue;
          break;
      }

      const div = createElement(
        "div",
        { class: `cell-content ${cellClass}` },
        value
      );

      return createElement("td", [div]);
    }) as VNode[];

    return createElement("tr", { class: "fat-border" }, th);
  },
});
